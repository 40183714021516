<template>
    <div class="Login full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height">
            <div class="info-area pa-4 pb-8 pt-15 mr-4 flex-grow-1 bg-white">
                <div class="title-bar d-flex pa-4 align-center">
                    <p v-if="screen === 'startup'">{{ $t('system.userManagement.deviceStartup') }}</p>
                </div>
                <!--Startup-->
                <div class="pt-8 full-height initialising d-flex flex-column" v-if="screen === 'startup'">
                    <p>{{ $t('system.userManagement.application') }} {{ $t('system.userManagement.version') }} {{startup.version}}</p>
                    <p class="pt-4 pb-8">{{startup.activity}}</p>
                    <p class="pt-4 error-sign" v-for="error in startup.messages" :key="error.id">{{error}}</p>
                    <v-spacer/>
                </div>
                <!--Expired-->
                <div class="pt-8 full-height initialising d-flex flex-column" v-if="screen === 'expired'">
                    <p>{{ $t('system.userManagement.passwordExpired') }}</p>
                </div>
                <!--Success-->
                <div class="pt-8 full-height initialising d-flex flex-column" v-if="screen === 'success'">
                    <p>{{ $t('system.userManagement.passwordSuccess') }}</p>
                </div>
            </div>
            <!--Startup & Success-->
            <div class="content-area pa-4 pt-15 flex-grow-1 bg-white d-flex flex-column" v-if="screen === 'startup' || screen === 'success'">
                <div class="title-bar d-flex pa-4 align-center">
                    <p>{{ $t('system.userManagement.login') }}</p>
                </div>
                <v-form ref="loginform" class="form flex-grow-1">
                    <p class="pt-8 pb-4">{{ $t('system.userManagement.username') }}</p>
                    <text-input inputName="login-user" @onInputFocus="onInputFocus"/>
                    <p class="pt-8 pb-4">{{ $t('system.userManagement.password') }}</p>
                    <text-input inputName="login-password" @onInputFocus="onInputFocus" type="password"/>
                    <p class="errorMessage pt-8 pb-4" v-if="loginFailed">{{ $t('login.loginFailed') }}</p>
                </v-form>


                <p class="date pb-4">{{startup.dateTime}}</p>
                <v-btn class="content-btn login-btn" elevation="0" :disabled="login.user =='' || login.user == null" @click="doLogin">{{ $t('system.userManagement.login') }}</v-btn>
            </div>
            <!--Expired-->
            <div class="content-area pa-4 pt-15 flex-grow-1 bg-white d-flex flex-column" v-if="screen === 'expired'">
                <div class="title-bar d-flex pa-4 align-center">
                    <p>{{ $t('system.userManagement.changePassword') }}</p>
                </div>
                <v-form ref="passwordform" class="form flex-grow-1">
                    <p class="pt-8 pb-4">{{ $t('system.userManagement.password') }}</p>
                    <text-input inputName="password" @onInputFocus="onInputFocus" type="password" :rules="[v => !!v || $t('validation.newPassword'), rules.maxLengthValue, rules.alphanumericSpecCharValue, rules.passwordSpecialChar]"/>
                    <p class="pt-8 pb-4">{{ $t('system.userManagement.passwordRepeat') }}</p>
                    <text-input inputName="passwordrepeat" @onInputFocus="onInputFocus" type="password"/>
                    <p class="errorMessage pt-8 pb-4" v-if="passwordNotMatching">{{ $t('login.passwordNotMatching') }}</p>
                </v-form>

                <p class="date pb-4">{{startup.dateTime}}</p>
                <v-btn class="content-btn login-btn" elevation="0" :disabled="this.password =='' || this.passwordrepeat =='' || this.password == null || this.passwordrepeat == null" @click="changePW">{{ $t('system.userManagement.changePassword') }}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import {mapState} from "vuex";

export default {
    name: 'Login',
    components: {
        TextInput,
    },
    props: {
    },
    data () {
        return{
            screen: 'startup',
            loginFailed: false,
            groupOfUser: null,
            passwordNotMatching: false,
            rules: {
                maxLengthValue: v => {
                    if (v){
                        return v.length < 24 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                alphanumericSpecCharValue: v => {
                    if (v){
                        let alphanumericSpecChar = /^[A-Za-z0-9*%&\-_/+?!@#.]+$/;
                        return alphanumericSpecChar.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
                passwordSpecialChar: v => {
                    if (v){
                        //get group settings of selected group
                        let group = this.grouplist.find(group => {
                            return group.id == this.groupOfUser;
                        });
                        //check if complexPassword activated
                        if (group && group.complexPassword){
                            let specialChar = /[*%&\-_/+?!@#.]/;
                            let capitalChar = /[A-Z]/;
                            let number = /[0-9]/;
                            if (specialChar.test(v) && capitalChar.test(v) && number.test(v) && (v.length >= 8)){
                                return true;
                            } else return `${this.$t('validation.complexPassword')}`;
                        } else return true;
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'startup',
            'login',
            'systemsettings',
            'password',
            'passwordrepeat',
            'grouplist',
            'activeUser'
        ]),
    },
    methods:{
        doLogin(){
            if (this.login.user != null) {
                this.$store.dispatch('doLogin', this.login)
                .then(response => {
                    if (response.status === 200) {
                        this.$store.dispatch('getAxiosSetter', ['/admin/device/systemsettings', 'systemsettings'])
                            .then(response =>{
                                if (response.status === 200){
                                    if (response.data.language === 'Deutsch'){
                                        this.$store.commit('setLanguage', 'de');
                                        this.$store.commit('setKeyboard', 'euro');
                                        this.$i18n.locale = 'de'
                                    } else {
                                        this.$store.commit('setLanguage', 'en');
                                        this.$store.commit('setKeyboard', 'en');
                                        this.$i18n.locale = 'en'
                                    }
                                }
                            });
                        this.$store.dispatch('getAxiosSetter', ['/admin/group/list', 'grouplist']);
                        this.$store.dispatch('getAxiosSetter', ['/disi/testing/batch/list', 'batchlist']);
                        this.$store.dispatch('getAxiosSetter', ['/disi/media/list', 'medialist']);
                        this.$store.dispatch('getAxiosSetter', ['/disi/methods/list', 'methodlist']);
                        this.$store.dispatch('getAxiosSetter', ['/disi/products/list', 'productlist']);
                        this.$store.dispatch('getAxiosSetter', ['/disi/reports/list', 'reportlist']);
                        this.groupOfUser = this.activeUser.groupId;

                        //check if Password is expired
                        if (this.activeUser.forcePasswordChange === 1) {
                            this.screen = 'expired';
                        } else {
                            this.$emit("resetInput");
                            this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations'])
                                .then(()=>{
                                    this.$router.push('/stations-overview');
                                });
                        }
                    } else {
                        this.loginFailed = true;
                    }
                })
            }
        },
        changePW(){
            this.$refs.passwordform.validate();
            if (this.$refs.passwordform.validate()) {
                if (this.password === this.passwordrepeat){
                    //do post for passwordchange
                    let User = {}
                    User.id = parseInt(this.activeUser.id);
                    User.password = this.password;
                    this.$store.dispatch('postAxiosNoSetter', ['admin/user/edit', User])
                    .then(response=>{
                        this.$store.commit('emptyPassword');
                        if (response.status === 200) {
                            this.$emit("resetInput");
                            this.screen = 'success';
                        } else {
                            this.logout();
                        }
                    });
                } else {
                    this.$store.commit('emptyPassword');
                    this.inputName = '';
                    this.passwordNotMatching = true;
                }
            }
        },
        logout(){
            this.$store.dispatch('postAxiosNoSetter', ['/admin/user/logout', {id: this.activeUser.id}])
            this.$store.commit('resetLogin');
            this.$store.commit('removeActiveUser');
            localStorage.removeItem('token');
            this.$emit("resetInput");
            this.screen = 'startup';
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    created(){
        this.$store.dispatch('getAxiosSetterNoToken', ['/admin/device/startup', 'startup'])
            .then(response=>{
                if (response.status === 200) {
                    if (this.systemsettings.language === 'Deutsch'){
                        this.$store.commit('setLanguage', 'de');
                        this.$store.commit('setKeyboard', 'euro');
                        this.$i18n.locale = 'de'
                    } else {
                        this.$store.commit('setLanguage', 'en');
                        this.$store.commit('setKeyboard', 'en');
                        this.$i18n.locale = 'en'
                    }
                }
            });
    },
    watch: {
        //hide loginFailed when new text is entered
        loginFailed: function(newStatus){
            if (newStatus){
                let username = this.$watch('$store.state.login.user', function() {
                    this.loginFailed = false;
                    stopWatch();
                });

                let password = this.$watch('$store.state.login.password', function() {
                    this.loginFailed = false;
                    stopWatch();
                });

                const stopWatch = function(){
                    username();
                    password();
                }
            }
        },
        //hide passwordNotMatching when new text is entered
        passwordNotMatching: function(newStatus){
            if (newStatus){
                let password = this.$watch('$store.state.password', function() {
                    this.passwordNotMatching = false;
                    stopWatch();
                });

                let passwordrepeat = this.$watch('$store.state.passwordrepeat', function() {
                    this.passwordNotMatching = false;
                    stopWatch();
                });

                const stopWatch = function(){
                    passwordrepeat();
                    password();
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">

.v-application .content-btn{
    &:hover, &:active{
        background-color: $disiIrisblue;
    }
}

.title-bar {
    background-color: $disiDarkblueMedium;
    font-weight: 500;
    height: 60px;
    width: 100%;
    position: absolute;
    top:0;
    left: 0;


    p {
        color: white;
        font-weight: 500;
    }
}

.info-area{
    min-width: 65%;
    min-height: 500px;
    position: relative;

    .error-sign{
        &::before{
            content: '!';
            display: inline-block;
            color: white;
            font-weight: 700;
            text-align: center;
            width: 21px;
            height: 21px;
            margin-right: 15px;
            background-color: $disiOrange;
        }
    }

    .loader{
        strong{
            color: $disiGrey;
        }
    }

}
.content-area{
    max-width: 35%;
    position: relative;

    .form{
        min-height: 330px;
    }

    .errorMessage{
        color: $disiRed;
    }

    .date{
        color: $disiBluegreyDark;
    }
}
</style>